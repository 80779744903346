<template>
    <div style="margin-top:40vh;font-size:30px;">
        <div>{{statuses[currentState]}}</div>
        <div v-if="currentState!==0" style="margin-top:40px;font-size:15px;cursor:pointer;" >
            Going back to Spext in {{countdown}} seconds
            <!-- <a v-if="currentState!==0" @click="goToDrive" >&lt; Back to Spext</a> -->
        </div>
    </div>
</template>
<script>
import CONSTANTS from '@/constants/CONSTANTS'
import * as firebase from 'firebase/app'
import 'firebase/database'
import axios from 'axios'
export default {
  name: 'invite',
  data () {
    return {
      statuses: {
        0: 'Loading...',
        200: 'Congratulations! Invited Successfully.',
        201: 'Already invited!',
        202: 'Sorry, you are already on a plan',
        500: 'Sorry, incorrect invite'
      },
      currentState: 0,
      countdown: 0,
      countdownId: ''
    }
  },
  mounted () {
    var vm = this
    console.log('mounted invite', vm.$parent.user.uid)
    firebase.database().ref(vm.$parent.user.uid + '/userInfo/billing/subscribed').once('value').then(function (snapshot) {
      console.log('mounted invited 2', snapshot.val())
      if (localStorage['invite'] === 'IndianPodcast') {
        if (!snapshot.exists()) {
          axios.get(CONSTANTS.SUBSCRIBE + `?id=` + vm.$parent.user.uid + `&plan=IndianPodcast`).then(function (res) {
            console.log('subscribed')
            vm.currentState = 200
            delete localStorage['invite']
            vm.beginCountdown()
          })
        } else if (snapshot.val() === 'IndianPodcast') {
          vm.currentState = 201
          delete localStorage['invite']
          vm.beginCountdown()
        } else {
          vm.currentState = 202
          delete localStorage['invite']
          vm.beginCountdown()
        }
      } else {
        console.log('Not a proper invite')
        vm.currentState = 500
        delete localStorage['invite']
        vm.beginCountdown()
      }
    })
  },
  methods: {
    goToDrive () {
      this.$router.replace({ 'name': 'drive' })
    },
    beginCountdown () {
      var vm = this
      if (vm.countdownId !== '') {
        clearInterval(vm.countdownId)
      }
      vm.countdown = 10
      vm.countdownId = setInterval(reduceTime, 1000)
      function reduceTime () {
        vm.countdown -= 1
        if (vm.countdown <= 1) {
          clearInterval(vm.countdownId)
          // vm.currentState = 0
          console.log('Route to drive')
          vm.$router.replace({ 'name': 'drive' })
        }
      }
    }
  }
}
</script>
